// 
// authentication.scss
//

.authentication-bg {
  background-color: $primary;
}

.account-card-box{
  background-color: $card-bg;
  padding: 7px;
  border-radius: 8px;
  
  .card{
    border: 4px solid $primary;
  }
}