// 
// coming-soon.scss
//

.counter-number {
    font-size: 60px;
    font-weight: $font-weight-semibold;
    text-align: center;
    color: $dark;
    span {
        font-size: 16px;
        font-weight: $font-weight-normal;
        display: block;
        text-transform: uppercase;
        padding-top: 5px;
        color: $success;
    }
}

.coming-box {
    float: left;
    width: 25%;
}
